/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from 'axios'
import { FC, ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import LoaderCogx from '../../../components/loader-cogx/Loader'
import {
  setQuizFinished,
  updateUserBilan,
} from '../../../redux/action-creators/users-actions-creators'
import { setSession } from '../../../services/storage-service'

import { deleteUsResponse } from '../../../services/bilan.service'
import './univers.scss'

interface UniverItem {
  id: number
  image: string
  name: string
  questionnaire: number
}

export const setCurrentUniversAPI = async (
  univer_id: string,
  userID?: any,
): Promise<any> => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_REST_API_LOCATION}/set-current-univer`,
      {
        univer_id,
        userID,
      },
    )
    return response.data
  } catch (error) {
    console.error('Error updating univers', error)
  }
}

const Univers: FC = (): ReactElement => {
  document.body.classList.remove('no-scroll')
  const [univers, setUnivers] = useState<UniverItem[]>([])
  const [userInfo, setUserInfo] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingItem, setLoadingItem] = useState<string | null>(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    localStorage.removeItem('currentresponseTemp')
    localStorage.removeItem('quiz-question')
    localStorage.removeItem('responseTemp')
    localStorage.removeItem('last_user_id')
  }, [])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_REST_API_LOCATION}/univer-user`)
      .then((response) => {
        setUnivers(response.data.list)
        setUserInfo(response.data.userInfo)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching univers data:', error)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <div className="skeleton">
        {' '}
        <LoaderCogx />{' '}
      </div>
    )
  }

  const handleClick = async (item: any) => {
    setLoadingItem(item.id.toString())
    const res = await setCurrentUniversAPI(item.id)

    if (res?.message === 'ok') {
      setSession('isStandard', 'true')
      navigate(`/quiz/${item.questionnaire}`)
    }
    if (res.message === 'not_finished') {
      if (res.currentresponseTemp.length > 0) {
        localStorage.setItem(
          'currentresponseTemp',
          JSON.stringify(res.currentresponseTemp),
        )
        localStorage.setItem(
          'responseTemp',
          JSON.stringify(
            res.currentresponseTemp.map((item: any) => item.question_id),
          ),
        )
        navigate('/quiz-not-finished')
        dispatch(setQuizFinished(false, res.have_question_not_finsihed.url))
      } else {
        setSession('isStandard', 'true')
        deleteUsResponse(res.have_question_not_finsihed.idUserHistory)
        navigate(`/quiz/${res.have_question_not_finsihed.idForm}`)
      }
    }

    dispatch(updateUserBilan(res.bilan))
    if (res.message === 'already_done') {
      if (localStorage.getItem('prestId') === '3') {
        navigate('/dashboard/thanks')
      }
      if (localStorage.getItem('prestId') === '2') {
        navigate('/dashboard/bilan')
      }
      if (localStorage.getItem('prestId') === '1') {
        navigate('/dashboard/defis')
      }
    }
  }

  return (
    <>
      <div className="main-content main-content--squeezed">
        <div className="container">
          <div className="header-top">
            <h1 className="heading"> {userInfo}</h1>
            <p>
              Bienvenue sur Engram, la plateforme qui permet d’adapter ses modes
              de travail aux besoins cognitifs et comportementaux humains.
              <br />
              Choisissez le thème que vous voulez explorer aujourd'hui :
            </p>
          </div>
          <div className="box bg-gray">
            <h2 className="box-title">Choisissez un thème</h2>
            <div className="row">
              {univers.map((item) => (
                <div key={item.id} className="col-12 col-md-6 col-lg-3 mb-4">
                  <div className="card h-100 bg-white text-center">
                    <h5 className="card-title"> {item.name}</h5>
                    <img
                      src={item.image}
                      className="card-img-top"
                      alt={`Theme ${item.id}`}
                    />
                    <button
                      onClick={() => handleClick(item)}
                      className="btn btn-small btn-green card-btn"
                      disabled={item && loadingItem === String(item?.id)}
                    >
                      {item && loadingItem === String(item?.id)
                        ? 'Chargement...'
                        : 'Commencer'}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Univers
