declare global {
  interface Window {
    _paq?: any[];
  }
}

let navigationStartTime: number;
let currentPageUrl: string = window.location.pathname + window.location.search;

// Démarrer le timer et enregistrer l'URL de départ
export const startNavigationTimer = (): void => {
  navigationStartTime = new Date().getTime();
  currentPageUrl = window.location.pathname + window.location.search;
};

// Injecte le script Matomo dans l'application
export const injectMatomoScript = (): void => {
  const script = document.createElement('script');
  script.async = true;
  script.text = `
    var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
      var u="https://cogx.matomo.cloud/"
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '1']);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src='https://cdn.matomo.cloud/cogx.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
    })();
  `;
  document.head.appendChild(script);
};

// Injecte un autre script Matomo dans l'application
export const injectMatomoScriptTwo = (): void => {
  const script = document.createElement('script');
  script.async = true;
  script.text = `
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/cogx.matomo.cloud/container_c9WuDfMt.js'; s.parentNode.insertBefore(g,s);
  `;
  document.head.appendChild(script);
};

// Suivre une vue de page
export const logPageView = (): void => {
  if (window._paq) {
    window._paq.push([
      'setCustomUrl',
      window.location.pathname + window.location.search,
    ]);
    window._paq.push(['trackPageView']);
  }
};

// Suivre un événement personnalisé
export const logEvent = (
  category: string,
  action: string,
  label: string = '',
  value?: any
): void => {
  if (window._paq) {
    const gaInfo = sessionStorage.getItem('gaInfo');
    const parsedGaInfo = gaInfo ? JSON.parse(gaInfo) : null;
    const email = parsedGaInfo?.email || 'N/A';
    const userGroup = parsedGaInfo?.groupe || 'N/A';

    const fullLabel = `${label} | Email: ${email}`;

    window._paq.push(['setCustomDimension', 1, userGroup]);
    window._paq.push(['setCustomDimension', 2, email]);

    console.log('Événement enregistré:', category, action, fullLabel);
    window._paq.push(['trackEvent', category, action, fullLabel, value]);

    window._paq.push(['deleteCustomDimension', 1]);
    window._paq.push(['deleteCustomDimension', 2]);
  }
};

// Configurer les propriétés de l'utilisateur
export const setUserProperties = (userId: string, groupId: string): void => {
  if (window._paq) {
    window._paq.push(['setUserId', userId]);
    window._paq.push(['setCustomDimension', 1, groupId]);
    window._paq.push(['setCustomDimension', 2, userId]);
  }
};

// Fonctions personnalisées pour chaque type d'événement

export const logMonBilanPageView = (userGroup: string): void => {
  /* logEvent(
    'Vue de Page',
    'Page Mon bilan vue',
    `groupe_utilisateur: ${userGroup}`
  ); */
};

export const logMonBilanUniqueVisitor = (userGroup: string): void => {
  logEvent(
    'Visiteur Unique',
    'Visiteur unique sur Mon bilan',
    `groupe_utilisateur: ${userGroup}`
  );
};

let startTime: number;

export const startTimer = (): void => {
  startTime = new Date().getTime();
};

export const logTimeSpentOnPage = (userGroup: string): void => {
  /* const endTime = new Date().getTime();
  const timeSpent = (endTime - startTime) / 1000; // Temps passé en secondes
  logEvent(
    'Temps Passé',
    'Temps passé sur la page Mon bilan',
    `groupe_utilisateur: ${userGroup}, temps_passe: ${timeSpent}s`
  ); */
};

export const logTimeSpentOnPageRessource = (userGroup: string): void => {
  /* const endTime = new Date().getTime();
  const timeSpent = (endTime - startTime) / 1000; // Temps passé en secondes
  logEvent(
    'Temps Passé',
    'Temps passé sur la page Ressource',
    `groupe_utilisateur: ${userGroup}, temps_passe: ${timeSpent}s`
  ); */
};

export const logTimeSpentOnPageChallenge = (userGroup: string): void => {
  /* const endTime = new Date().getTime();
  const timeSpent = (endTime - startTime) / 1000; // Temps passé en secondes
  logEvent(
    'Temps Passé',
    'Temps passé sur la page Défi',
    `groupe_utilisateur: ${userGroup}, temps_passe: ${timeSpent}s`
  ); */
};

// Nombre de clics sur un défi choisi
export const logChallengeClick = (
  challengeName: string,
  userGroup: string,
  value?: any
): void => {
  logEvent(
    'Clic Défi',
    `Défi cliqué: ${challengeName}`,
    `groupe_utilisateur: ${userGroup}`
    // value
  );
};

// Nombre de clics sur une ressource choisie
export const logResourceClick = (
  resourceName: string,
  userGroup: string,
  value?: any
): void => {
  logEvent(
    'Clic Ressource',
    `Ressource cliquée: ${resourceName}`,
    `groupe_utilisateur: ${userGroup}`
    // value
  );
};

// Suivre la navigation et le temps passé sur chaque page
export const logNavigationPath = (userGroup: string): void => {
  const endTime = new Date().getTime();
  const timeSpent = (endTime - navigationStartTime) / 1000;

  console.log('Temps passé sur', currentPageUrl, ':', timeSpent, 'secondes');
  if (currentPageUrl !== '/') {
    logEvent(
      'Navigation',
      `Navigation vers ${currentPageUrl}`,
      `groupe_utilisateur: ${userGroup}`,
      timeSpent
    );
  }

  navigationStartTime = endTime;
  currentPageUrl = window.location.pathname + window.location.search;
};

startNavigationTimer();
