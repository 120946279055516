import { useMsal } from '@azure/msal-react'
import React, { FC, ReactElement } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { resetDefis } from '../../../redux/action-creators/defis-actions-creators'
import { resetAllMicroVariables } from '../../../redux/action-creators/micro-variable-actions-creators'
import { resetQuiz } from '../../../redux/action-creators/quiz-actions-creators'
import { resetRessources } from '../../../redux/action-creators/ressources-actions-creators'
import {
  resetUser,
  setIsLogOut,
} from '../../../redux/action-creators/users-actions-creators'
import { RootState } from '../../../redux/reducers'
import ButtonCogx from '../../button/button-cogx'
import './logout.scss'

const Logout: FC<any> = (): ReactElement => {
  const dispatch = useDispatch()
  const { instance } = useMsal()
  const state = useSelector((state: RootState) => state.user)
  const navaBarRefs = React.useRef<any>(null)
  function handleClose() {
    const activeAccount = instance.getAllAccounts()
    console.log('emailAzure', activeAccount)

    if (activeAccount.length > 0) {
      instance
        .logoutPopup({
          postLogoutRedirectUri: '/',
        })
        .then(() => {
          console.log('Déconnexion réussie azure')
          dispatch(resetUser())
          dispatch(resetQuiz())
          dispatch(resetDefis())
          dispatch(resetRessources())
          dispatch(resetAllMicroVariables())
        })
        .catch((err) => {
          console.error('Erreur lors de la déconnexion SSO:', err)
        })
    } else {
      dispatch(resetUser())
      dispatch(resetQuiz())
      dispatch(resetDefis())
      dispatch(resetRessources())
      dispatch(resetAllMicroVariables())
    }
  }

  function handleCloseNo() {
    if (navaBarRefs.current) {
      navaBarRefs.current.click()
    }
    dispatch(setIsLogOut(false))
  }

  const clearAllCookies = () => {
    const cookies = document.cookie.split(';')
    cookies.forEach((cookie) => {
      const cookieName = cookie.split('=')[0].trim()
      document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`
    })
  }

  document.body.classList.remove('modal-dialog')
  return (
    <Modal show={state.isLogout} onHide={handleCloseNo} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Déconnexion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Êtes-vous sûr·e de vous vouloir vous déconnecter ?
      </Modal.Body>
      <Modal.Footer className="footer-logout">
        <ButtonCogx
          label="Oui"
          controller={handleClose}
          className="btn btn-secondary"
        />
        <ButtonCogx
          label="Non"
          controller={handleCloseNo}
          className="btn btn-primary btn-green"
        />
      </Modal.Footer>
    </Modal>
  )
}
export default Logout
