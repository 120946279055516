import { LoadableComponent } from '@loadable/component'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from '../../redux/reducers'
import { getKeyLocal } from '../../services/storage-service'

interface PropType {
  component: LoadableComponent<any>
  isVisible?: boolean
  restrictedPaths?: string[]
}

const PrivateThanksRoute: FC<PropType> = ({
  component: Component,
  isVisible,
  restrictedPaths = [],
}) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated,
  )
  const prestId = useSelector((state: RootState) => state.user.prestation)
  const prestationId = getKeyLocal('prestId')

  if (isAuthenticated) {
    if (prestationId === '3' || prestId === '3') {
      return <Component isVisible={isVisible} />
    } else {
      return <Navigate to="/" />
    }
  }
  return <Navigate to="/" />
}

export default PrivateThanksRoute
