import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react'
import './Input-cogx.scss'
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  className: string
  isreadonly?: boolean
  error?: string
  placeholder?: string
  type?: string
  value: string | number | any
  controller: (data: any) => void
}

const InputCogx: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    className,
    type,
    value,
    error,
    isreadonly,
    placeholder,
    controller,
  },
  ref,
) => {
  return (
    <React.Fragment>
      <label>{label}</label>
      <input
        className={className}
        type={type}
        placeholder={placeholder}
        readOnly={isreadonly}
        name={name}
        value={value}
        onChange={controller}
      />
      {error ? (
        <p className="text-danger" data-cy="error-message">
          {error}
        </p>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

const FormInput = React.forwardRef(InputCogx)

export default FormInput
