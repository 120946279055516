import axios from 'axios';
import { IUser } from '../models/user';

/**
 *
 * @param user
 * @returns
 */

export async function registerUser(user: IUser) {
  return await axios.post(
    `${process.env.REACT_APP_REST_API_LOCATION}/auth/local/register`,
    user
  );
}

/**
 *
 * @param user
 * @returns
 */

export async function login(user: IUser) {
  return await axios.post(
    `${process.env.REACT_APP_REST_API_LOCATION}/auth/local`,
    user
  );
}

export async function forgotPassword(email: string) {
  return await axios.post(
    `${process.env.REACT_APP_REST_API_LOCATION}/auth/forgot-password`,
    { email: email }
  );
}

export async function resetPassword(data: any) {
  const payload = {
    password: data.password,
    passwordConfirmation: data.passwordConfirmation,
    code: data.code,
  };

  return await axios.post(
    `${process.env.REACT_APP_REST_API_LOCATION}/auth/reset-password`,
    payload
  );
}

export async function updateFirstConnexionApi(token: string) {
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios.put(
    `${process.env.REACT_APP_REST_API_LOCATION}/update-first-connexion`,
    {},
    headers
  );
}

export async function resetFirstPassword(data: any) {
  const payload = {
    password: data.password,
    passwordConfirmation: data.passwordConfirmation,
  };

  return await axios.put(
    `${process.env.REACT_APP_REST_API_LOCATION}/reset-first-password`,
    payload
  );
}

export async function meUser() {
  return await axios.get(
    `${process.env.REACT_APP_REST_API_LOCATION}/users-permissions/me-cogx`
  );
}

export async function resetUserHistory(id: string) {
  return await axios.put(
    `${process.env.REACT_APP_REST_API_LOCATION}/reset-user-history/${id}`
  );
}
