import axios from 'axios';
import { IMacrovariable } from '../models/macro-type';

export function getQuestionByIdMacroVariable(
  allQuestion: [],
  macro_type_id: number
) {
  const questions =
    allQuestion.length > 0 &&
    allQuestion.filter((res: IMacrovariable) => {
      return res.macro_type.id === macro_type_id;
    });

  return {
    current_questions: questions,
  };
}
/**
 *
 * @param allQUestion
 * @returns
 */
export function responseByUser(allQUestion: []) {
  const responseUser = allQUestion.map((res: any) => {
    return {
      macro_type: {
        id: res.macro_type.id,
        name: res.macro_type.name,
      },

      questions: res.questions.map((data: any) => {
        return { ...data, user_response: '' };
      }),
    };
  });

  return responseUser;
}

export async function checkLastQuiz(id: string) {
  return await axios.get(
    `${process.env.REACT_APP_REST_API_LOCATION}/check-quiz-story/${id}}`
  );
}

export function mergeQuestions(allQUestion: []) {
  return allQUestion.map((o: any) => o.questions).flat();
}

// in order to check in our database when the user will reconnect, if they were able to complete the questionnaires until the end
export async function addHistory(
  questionaireId: string,
  isRestart: any,
  univerId?: any
) {
  return await axios.post(
    `${process.env.REACT_APP_REST_API_LOCATION}/add-history`,
    { form: questionaireId, isRestart: isRestart, univer: univerId }
  );
}

export async function setUserHistoryRestart(id: string) {
  return await axios.put(
    `${process.env.REACT_APP_REST_API_LOCATION}/set-history-restart/${id}`
  );
}

export async function updateFormAssignedApi() {
  return await axios.put(
    `${process.env.REACT_APP_REST_API_LOCATION}/update-form-assigned`
  );
}

export async function saveResponseUser(
  response: [],
  idHistory: any,
  formId: any,
  responseTemporaire?: any
) {
  return await axios.post(
    `${process.env.REACT_APP_REST_API_LOCATION}/save-response-user`,
    {
      form: formatPayloadQuestion(response, idHistory),
      formId: formId,
      responseTemporaire: responseTemporaire,
    }
  );
}

export async function updateScore(id: string, question: any, score: any) {
  return await axios.put(
    `${process.env.REACT_APP_REST_API_LOCATION}/update-score/${id}/${question}`,
    { new_score: score }
  );
}

export async function getLastUserHistory() {
  return await axios.get(
    `${process.env.REACT_APP_REST_API_LOCATION}/get-last-quiz`
  );
}

export async function mailingLog(res: any) {
  const payload = {
    subject: res.subject,
    data: res.subject,
    email: res.email,
  };
  return await axios.post(
    `${process.env.REACT_APP_REST_API_LOCATION}/mailing-log`,
    payload
  );
}

function formatPayloadQuestion(data: any, idHistory: any) {
  const payload = data.map((res: any) => {
    return {
      free_text_answer: res.type === 'text' ? res.response_user : '',
      question: res.question_id,
      score_reponse: res.score,
      date_time_reponse: res.date_time_reponse,
      response:
        res.type === 'multiple' || res.type === 'radio'
          ? parseInt(res.response_user)
          : 0,
      user_history: idHistory,
    };
  });

  return payload;
}

export async function updateUserHistory(id: string) {
  return await axios.put(
    `${process.env.REACT_APP_REST_API_LOCATION}/update-history/${id}`
  );
}
export function groupByMacroVariableResponse(response: any) {
  const results = response?.reduce((results: any, org: any) => {
    (results[org?.macro_variable] = results[org?.macro_variable] || []).push(
      org
    );

    return results;
  }, []);

  return removeArrayEmpty(results);
}

export function groupByMicroVariableResponse(response: any) {
  const results = response.reduce((results: any, org: any) => {
    (results[org.micro_variable_id] =
      results[org.micro_variable_id] || []).push(org);

    return results;
  }, []);

  return removeArrayEmpty(results);
}

export function groupByMicroVariablePositiveResponse(response: any) {
  const results = response.reduce((results: any, org: any) => {
    (results[org.micro_variable_id] =
      results[org.micro_variable_id] || []).push(org);

    return results;
  }, []);

  return removeArrayEmptyPositve(results);
}

export function groupByMicroVariableNegativeResponse(response: any) {
  const results = response.reduce((results: any, org: any) => {
    (results[org.micro_variable_id] =
      results[org.micro_variable_id] || []).push(org);

    return results;
  }, []);

  return removeArrayEmptyNegative(results);
}

function removeArrayEmpty(array: any) {
  const response = array?.filter((res: any) => {
    return res !== null || res !== undefined;
  });

  return response;
}

function removeArrayEmptyPositve(array: any) {
  const response = array?.filter((res: any) => {
    return (
      (res !== null || res !== undefined) &&
      res[0].micro_variable_type &&
      res[0].micro_variable_type === 'Positive'
    );
  });

  return response;
}

export function checkPositveMicroVariable(data: any) {
  const response = data.filter((res: any) => {
    return res.micro_variable_type === 'Positive';
  });

  return response.length > 0 ? true : false;
}

export function checkNegativeMicroVariable(data: any) {
  const response = data.filter((res: any) => {
    return res.micro_variable_type === 'Négative' || !res.micro_variable_type;
  });

  return response.length > 0 ? true : false;
}

function removeArrayEmptyNegative(array: any) {
  const response = array.filter((res: any) => {
    return (
      ((res !== null || res !== undefined) && !res[0].micro_variable_type) ||
      res[0].micro_variable_type === 'Négative'
    );
  });

  return response;
}
