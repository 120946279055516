import {
  getKeyLocal,
  getObjectLocal,
  getObjectSessionStorage,
  setObjectSessionStorage,
  setObjectStorage,
} from '../../services/storage-service';
import { UserActionsTypes } from '../action-types/users-actions-types';
import { Action } from '../actions/users-actions';

const initialState = {
  user: getObjectLocal('user')?.jwt
    ? getObjectLocal('user')
    : getObjectSessionStorage('user')?.jwt
    ? getObjectSessionStorage('user')
    : false,
  isAuthenticated: getObjectLocal('user')?.jwt
    ? true
    : getObjectSessionStorage('user')?.jwt
    ? true
    : false,

  loading: false,
  error: false,
  isLogout: false,
  isTokenExpired: false,
  isNewQuiz: false,
  idNewQuiz: null,
  isQuizFromUrl: true,
  accessApp: true,
  isQuizFinished: checkQuizFinished(),
  linkQuiz: checkQuizUrl(),
  bilan: getObjectLocal('user')?.jwt
    ? getObjectLocal('user').user.bilan
    : getObjectSessionStorage('user')?.jwt
    ? getObjectSessionStorage('user').user.bilan
    : [],
  prestation: getKeyLocal('prestId'),
  gaInfo: getObjectSessionStorage('gaInfo') || null,
} as any;

function checkQuizFinished() {
  if (
    !getObjectLocal('user') ||
    !getObjectSessionStorage('user')
    // getObjectLocal('user')?.user?.user_histories?.isRestart === true ||
    //  getObjectSessionStorage('user').user?.user_histories?.isRestart === true
  ) {
    return true;
  }
  if (
    (getObjectLocal('user')?.jwt &&
      !getObjectLocal('user')?.user?.user_histories?.url) ||
    getObjectLocal('user')?.user?.user_histories?.active === false
  ) {
    return true;
  }

  if (
    getObjectLocal('user')?.jwt &&
    getObjectLocal('user')?.user?.user_histories?.url &&
    getObjectLocal('user')?.user?.user_histories?.active === true
  ) {
    return false;
  }

  if (
    getObjectSessionStorage('user')?.jwt &&
    getObjectSessionStorage('user')?.user?.user_histories?.url &&
    getObjectSessionStorage('user')?.user?.user_histories?.active === true
  ) {
    return false;
  } else {
    return true;
  }
}

function checkQuizUrl() {
  if (
    !getObjectLocal('user') ||
    !getObjectSessionStorage('user') ||
    getObjectLocal('user')?.user?.user_histories?.isRestart === true ||
    getObjectSessionStorage('user').user?.user_histories?.isRestart === true
  ) {
    return '';
  }
  if (
    (getObjectLocal('user')?.jwt &&
      !getObjectLocal('user')?.user?.user_histories?.url) ||
    getObjectLocal('user')?.user?.user_histories?.active === false
  ) {
    return '';
  }

  if (
    getObjectLocal('user')?.jwt &&
    getObjectLocal('user')?.user?.user_histories?.url &&
    getObjectLocal('user')?.user?.user_histories?.active === true
  ) {
    return getObjectLocal('user')?.user?.user_histories?.url;
  }

  if (
    getObjectSessionStorage('user')?.jwt &&
    getObjectSessionStorage('user')?.user?.user_histories?.url &&
    getObjectSessionStorage('user')?.user?.user_histories?.active === true
  ) {
    return getObjectSessionStorage('user')?.user?.user_histories?.url;
  } else {
    return '';
  }
}

function updateUserHistory(data: any) {
  if (data?.user?.user_histories) {
    data.user.user_histories = [];
  }
  getObjectLocal('user')?.jwt
    ? setObjectStorage('user', data)
    : setObjectSessionStorage('user', data);
  return data;
}

function updateUserFirstConnexion(data: any) {
  data.user.isFirstConnexion = false;

  getObjectLocal('user')?.jwt
    ? setObjectStorage('user', data)
    : setObjectSessionStorage('user', data);
  return data;
}

function updateFormAssigned(data: any) {
  data.user.form_assigned = false;

  getObjectLocal('user')?.jwt
    ? setObjectStorage('user', data)
    : setObjectSessionStorage('user', data);
  return data;
}

function updateUserBilan(current_user: any, bilan: any) {
  current_user.user.bilan = bilan;
  getObjectLocal('user')?.jwt
    ? setObjectStorage('user', current_user)
    : setObjectSessionStorage('user', current_user);
  return current_user;
}

const reducer = (state = initialState, action: Action): any => {
  switch (action.type) {
    case UserActionsTypes.GET_INFO_USER_REQUEST:
      return { ...state, loading: true, error: false };

    case UserActionsTypes.GET_INFO_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isAuthenticated: true,
        user: action.payload,
      };

    case UserActionsTypes.MERGE_INFO_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    case UserActionsTypes.GET_INFO_USER_FAILED:
      return { ...state, loading: false, error: true };

    case UserActionsTypes.UPDATE_GA_INFORMATION:
      setObjectSessionStorage('gaInfo', action.payload);
      return { ...state, gaInfo: action.payload };

    case UserActionsTypes.SET_IS_TOKEN_EXPIRED:
      return { ...state, isTokenExpired: action.payload };

    case UserActionsTypes.SET_IS_CONNECTED:
      return { ...state, isAuthenticated: !state.isAuthenticated };

    case UserActionsTypes.SET_QUIZ_FINISHED:
      return {
        ...state,
        isQuizFinished: action.payload,
        linkQuiz: action.url,
        isQuizFromUrl: false,
      };

    case UserActionsTypes.SET_IS_LOG_OUT:
      return { ...state, isLogout: action.payload };

    case UserActionsTypes.UPDATE_STATE_USER:
      return { ...state, user: action.payload };

    case UserActionsTypes.UPDATE_USER_HISTORY_USER:
      return {
        ...state,
        user: updateUserHistory(state.user),
        isQuizFinished: true,
      };

    case UserActionsTypes.UPDATE_FIRST_CONNEXION:
      return {
        ...state,
        user: updateUserFirstConnexion(state.user),
      };

    case UserActionsTypes.UPDATE_FORM_ASSIGNED:
      return {
        ...state,
        user: updateFormAssigned(state.user),
      };

    case UserActionsTypes.UPDATE_USER_BILAN:
      return {
        ...state,
        user: updateUserBilan(state?.user, action.payload),
      };

    case UserActionsTypes.SET_ACCESS_EXPIRED:
      return {
        ...state,
        accessApp: !state.accessApp,
      };

    case UserActionsTypes.SET_NEW_QUIZ:
      return { ...state, isNewQuiz: !state.isNewQuiz };

    case UserActionsTypes.SET_ID_NEW_QUIZ:
      return { ...state, idNewQuiz: action.payload };

    case UserActionsTypes.UPDATE_PRESTATION:
      return { ...state, prestation: action.payload };

    case UserActionsTypes.RESET_USER:
      return {
        isAuthenticated: false,
        loading: false,
        error: false,
        user: {},
        isTokenExpired: false,
        isNewQuiz: false,
        isLogout: false,
        isQuizFinished: true,
        accessApp: true,
        isQuizFromUrl: true,
        idNewQuiz: null,
        linkQuiz: '',
        bilan: [],
        gaInfo: null,
      };

    default:
      return state;
  }
};

export default reducer;
