export enum UserActionsTypes {
  GET_INFO_USER_SUCCESS = 'GET_INFO_USER_SUCCESS',
  GET_INFO_USER_FAILED = 'GET_INFO_USER_FAILED',
  GET_INFO_USER_REQUEST = 'GET_INFO_USER_REQUEST',
  SET_IS_CONNECTED = 'SET_IS_CONNECTED',
  SET_IS_TOKEN_EXPIRED = 'SET_IS_TOKEN_EXPIRED',
  RESET_USER = 'RESET_USER',
  SET_IS_LOG_OUT = 'SET_IS_LOG_OUT',
  SET_QUIZ_FINISHED = 'SET_QUIZ_FINISHED',
  UPDATE_STATE_USER = 'UPDATE_STATE_USER',
  UPDATE_USER_HISTORY_USER = 'UPDATE_USER_HISTORY_USER',
  UPDATE_USER_BILAN = 'UPDATE_USER_BILAN',
  UPDATE_FIRST_CONNEXION = 'UPDATE_FIRST_CONNEXION',
  UPDATE_FORM_ASSIGNED = 'UPDATE_FORM_ASSIGNED',
  SET_NEW_QUIZ = 'SET_NEW_QUIZ',
  SET_ID_NEW_QUIZ = 'SET_ID_NEW_QUIZ',
  SET_ACCESS_EXPIRED = 'SET_ACCESS_EXPIRED',
  MERGE_INFO_USER = 'MERGE_INFO_USER',
  UPDATE_PRESTATION = 'UPDATE_PRESTATION',
  UPDATE_GA_INFORMATION = 'UPDATE_GA_INFORMATION',
}
