import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import './cookie.scss'

interface CookieNoticeProps {
  onAccept: () => void
  onReject: () => void
}

const CookieNotice: React.FC<CookieNoticeProps> = ({ onAccept, onReject }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const hasConsented = Cookies.get('cookieConsent')
    if (!hasConsented) {
      setTimeout(() => setIsVisible(true), 1500)
    }
  }, [])

  const handleAccept = () => {
    // Définit un cookie qui expire dans 2 mois (60 jours)
    Cookies.set('cookieConsent', 'accepted', { expires: 60 })
    onAccept()
    setIsVisible(false)
  }

  const handleReject = () => {
    // Définit un cookie qui expire dans 2 mois (60 jours)
    Cookies.set('cookieConsent', 'rejected', { expires: 60 })
    onReject()
    setIsVisible(false)
  }

  return isVisible ? (
    <div
      className="cookie-notice fixed-bottom bg-light border-top p-3 fade show transition-opacity d-lg-flex align-items-center justify-content-between"
      style={{
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.5s ease-in-out',
      }}
      role="alert"
    >
      <div className="cookie-notice__left">
        <h5 className="mb-2">Vos préférences sur les cookies</h5>
        <p>
          Nous utilisons des cookies uniquement à des fins statistiques pour
          analyser et améliorer l'expérience utilisateur sur Engram. Aucune
          donnée personnelle n'est partagée à des fins commerciales ou
          publicitaire.
        </p>
      </div>
      <div className="d-flex flex-wrap justify-content-lg-end align-items-center">
        <button
          className="btn btn-primary btn-green me-2"
          onClick={handleAccept}
        >
          Accepter
        </button>
        <button className="btn btn-secondary me-2" onClick={handleReject}>
          Refuser
        </button>
        <a
          href="https://cogx.fr/cgu-engram"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-link"
        >
          En savoir plus
        </a>
      </div>
    </div>
  ) : null
}

export default CookieNotice
