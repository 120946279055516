import parse from 'html-react-parser'
import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react'
import './checkbox-cogx.scss'
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  id?: string
  isChecked?: boolean
  label: any
  className: string
  isreadonly?: boolean
  error?: string
  placeholder?: string
  value: string | number | any
  controller: (data: any) => void
}

const InputCheckBoxCogx: ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = ({ label, value, id, isChecked, name, error, controller }, ref) => {
  return (
    <div className="form-radio">
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={isChecked}
        className={
          isChecked ? 'checkbox-bouton-cogx-checked' : 'checkbox-bouton-cogx'
        }
        onChange={controller}
      />
      <React.Fragment>
        <label htmlFor={id}>{parse(label)}</label>
        {error ? <p className="text-danger"> {error}</p> : ''}
      </React.Fragment>
    </div>
  )
}

const FormInput = React.forwardRef(InputCheckBoxCogx)

export default FormInput
