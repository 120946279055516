import { FC, ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ButtonCogx from '../../components/button/button-cogx'
import InputCheckBoxCogx from '../../components/checkbox/checkbox-cogx'
import InputCogx from '../../components/input/Input-cogx'
import LoaderCogx from '../../components/loader-cogx/Loader'
import { updateFirstConnexion } from '../../redux/action-creators/users-actions-creators'
import { RootState } from '../../redux/reducers'
import { resetFirstPassword } from '../../services/auth.service'
import { setSession } from '../../services/storage-service'
import {
  regexCheckNumeric,
  regexLengthPassword,
  regexLowerCase,
  regexSpecialChar,
  regexUperCase,
} from '../../validators/password'
import { setCurrentUniversAPI } from '../dashboard/univers/univers'
import './forgot-password.scss'

const ChangePasswordFirstConnexion: FC<any> = (): ReactElement => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [inputPassword, setInputPassword] = useState(true)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [inputConfirmPassword, setInputConfirmPassword] = useState(true)
  const [isSubmitted, setSubmitted] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const [optIn, setOptIn] = useState(false)

  function optInChnage() {
    setOptIn(!optIn)
  }

  const navigate = useNavigate()
  function showPassword() {
    setInputPassword(!inputPassword)
  }

  function showConfirmPassword() {
    setInputConfirmPassword(!inputConfirmPassword)
  }

  const form_assigned = useSelector(
    (state: RootState) => state?.user?.user?.user?.form_assigned,
  )

  function resetPassWord(e: any) {
    setSubmitted(true)
    const payload = {
      password: password,
      passwordConfirmation: confirmPassword,
    }

    if (
      regexLengthPassword.test(password) &&
      regexLowerCase.test(password) &&
      regexUperCase.test(password) &&
      regexSpecialChar.test(password) &&
      regexCheckNumeric.test(password) &&
      password === confirmPassword
    ) {
      setLoading(true)
      resetFirstPassword(payload)
        .then(async (response) => {
          setLoading(true)
          toast.success('Modification  mot de passe avec succès', {
            position: 'bottom-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            progress: undefined,
          })

          dispatch(updateFirstConnexion())

          if (response?.data?.isUnivers) {
            document.body.classList.add('page')
            navigate(`/dashboard/univers`)
            return
          }
          if (response?.data?.oneUniv) {
            const res = await setCurrentUniversAPI(response?.data?.univerId)
            if (res?.message === 'ok') {
              setSession('isStandard', 'true')
              navigate(`/quiz/${response?.data?.formId}`)
            }
            return
          }
          if (form_assigned && !response?.data?.isUnivers) {
            navigate(`/quiz/${form_assigned}?form_assigned=true`)
          } else {
            document.body.classList.add('page')
            navigate(`/dashboard/defis`)
          }
        })
        .catch((error: any) => {
          setLoading(false)
        })
    }
    e.preventDefault()
  }

  function checkPassWord() {
    return password &&
      (!regexLengthPassword.test(password) ||
        !regexLowerCase.test(password) ||
        !regexUperCase.test(password) ||
        !regexSpecialChar.test(password) ||
        !regexCheckNumeric.test(password))
      ? 'Minimum 6 caractères, au moins 1 chiffre, 1 caractère spécial, 1 lettre minuscule et 1 lettre majuscule.'
      : isSubmitted && !password
      ? 'Ce champ est requis'
      : ''
  }

  function checkConfirmPassword() {
    return password && confirmPassword && password !== confirmPassword
      ? 'Le mot de passe et le mot de passe de confirmation ne correspondent pas.'
      : isSubmitted && !confirmPassword
      ? 'Ce champ est requis'
      : ''
  }

  document.body.classList.remove('page')

  return (
    <div className="login">
      <form className="login__form">
        <div className="form-login mdp">
          <h1 className="h1-title text-center title-green">
            Votre mot de passe
          </h1>
          <p className="form-login__desc text-center">
            Veuillez choisir un mot de passe définitif pour accéder à votre
            espace.
          </p>

          <div className="form-group">
            <InputCogx
              name="passsword"
              label="Nouveau mot de passe"
              type={inputPassword ? 'password' : 'text'}
              className={
                checkPassWord() ? 'form-control input-error' : 'form-control'
              }
              value={password}
              error={checkPassWord()}
              placeholder="Ex: Mjod!95"
              controller={(e) => setPassword(e.target.value)}
            />
            <div onClick={showPassword} className="eye">
              <span
                className={inputPassword ? 'icon-eye-close' : 'icon-eye-open'}
              ></span>
            </div>
          </div>

          <div className="form-group">
            <InputCogx
              name="passsword"
              label="Confirmation du nouveau mot de passe"
              type={inputConfirmPassword ? 'password' : 'text'}
              className={
                checkConfirmPassword()
                  ? 'form-control input-error'
                  : 'form-control'
              }
              error={checkConfirmPassword()}
              value={confirmPassword}
              placeholder="Ex: Mjod!95"
              controller={(e) => setConfirmPassword(e.target.value)}
            />
            <div onClick={showConfirmPassword} className="eye">
              <span
                className={
                  inputConfirmPassword ? 'icon-eye-close' : 'icon-eye-open'
                }
              ></span>
            </div>
          </div>

          <div className="form-group" onClick={optInChnage}>
            <InputCheckBoxCogx
              value={optIn}
              isChecked={optIn}
              controller={optInChnage}
              label="Se souvenir de mon mot de passe sur ce navigateur"
              className="form-check"
              name=""
            />
          </div>

          <div className="btn-connect">
            {!isLoading ? (
              <ButtonCogx
                label="Je me connecte"
                controller={resetPassWord}
                className="btn btn-primary btn-green"
              />
            ) : (
              <LoaderCogx
                bg="loader-custom--transparent"
                type="loader-custom--relative"
              />
            )}
          </div>
        </div>
      </form>{' '}
      <div className="login__bg bg-yellow ">
        <picture>
          <img
            src="./assets/images/Illustration-connexion.svg"
            alt="background password forgotten"
            width={443}
            height={550}
          />
        </picture>
      </div>
    </div>
  )
}
export default ChangePasswordFirstConnexion
