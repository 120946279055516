import { Dispatch } from 'redux';
import { IUser } from '../../models/user';
import { clearLocal, clearSession } from '../../services/storage-service';
import { UserActionsTypes } from '../action-types/users-actions-types';
import { Action } from '../actions/users-actions';

export const getInfoUserRequest = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.GET_INFO_USER_REQUEST,
    });
  };
};

export const updateUser = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.UPDATE_STATE_USER,
      payload: data,
    });
  };
};

export const updateGaInformation = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.UPDATE_GA_INFORMATION,
      payload: data,
    });
  };
};

export const updateUserHistoryUser = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.UPDATE_USER_HISTORY_USER,
    });
  };
};

export const updateUserBilan = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.UPDATE_USER_BILAN,
      payload: data,
    });
  };
};

export const setIsTokenExpired = (data: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.SET_IS_TOKEN_EXPIRED,
      payload: data,
    });
  };
};

export const setQuizFinished = (data: boolean, url: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.SET_QUIZ_FINISHED,
      payload: data,
      url: url,
    });
  };
};

export const setIsLogOut = (data: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.SET_IS_LOG_OUT,
      payload: data,
    });
  };
};

export const resetUser = () => {
  clearLocal();
  clearSession();
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.RESET_USER,
    });
  };
};

export const getInfoUserSucces = (user: IUser) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.GET_INFO_USER_SUCCESS,
      payload: user,
    });
  };
};

export const mergeInfoUser = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.MERGE_INFO_USER,
      payload: data,
    });
  };
};

export const updatePrestation = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.UPDATE_PRESTATION,
      payload: data,
    });
  };
};

export const getInfoUserFailed = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.GET_INFO_USER_FAILED,
    });
  };
};

export const updateFormAssigned = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.UPDATE_FORM_ASSIGNED,
    });
  };
};

export const updateFirstConnexion = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.UPDATE_FIRST_CONNEXION,
    });
  };
};

export const setNewQuiz = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.SET_NEW_QUIZ,
    });
  };
};

export const setIdNewQuiz = (data: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.SET_ID_NEW_QUIZ,
      payload: data,
    });
  };
};

// set connected test
export const setIsConnected = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.SET_IS_CONNECTED,
    });
  };
};

export const setPermissionAccess = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionsTypes.SET_ACCESS_EXPIRED,
    });
  };
};
