import axios from 'axios';

export async function getBilanUser() {
  return await axios.get(
    `${process.env.REACT_APP_REST_API_LOCATION}/bilan-user`
  );
}

export async function getDefisNews() {
  return await axios.get(
    `${process.env.REACT_APP_REST_API_LOCATION}/defis-news`
  );
}

export async function getAllBilanUser(page?: number) {
  return await axios.get(
    `${process.env.REACT_APP_REST_API_LOCATION}/all-bilan-user?page=${page}`
  );
}
export async function bilanUser(id?: number | string) {
  return await axios.get(
    `${process.env.REACT_APP_REST_API_LOCATION}/bilan-user/${id}`
  );
}

export async function deleteUsResponse(id?: number | string) {
  return await axios.delete(
    `${process.env.REACT_APP_REST_API_LOCATION}/delete-us-response/${id}`
  );
}

export async function deleteUs(id?: number | string) {
  return await axios.delete(
    `${process.env.REACT_APP_REST_API_LOCATION}/delete-history/${id}`
  );
}
