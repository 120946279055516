import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: 'acac7b66-6851-4263-87fc-3d723f523e2b',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: process.env.REACT_APP_CALL_BACK_AZURE,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};
