import { LoadableComponent } from '@loadable/component'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from '../../redux/reducers'
import { getKeyLocal } from '../../services/storage-service'

interface PropType {
  component: LoadableComponent<any>
  isVisible?: boolean
  restrictedPaths?: string[]
}

const PrivateBilanRoute: FC<PropType> = ({
  component: Component,
  isVisible,
  restrictedPaths = [],
}) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated,
  )

  const prestId = useSelector((state: RootState) => state.user.prestation)

  const prestationId = getKeyLocal('prestId')

  if (isAuthenticated) {
    if (
      prestationId === '1' ||
      prestationId === '2' ||
      prestId === '1' ||
      prestId === '2'
    ) {
      return <Component isVisible={isVisible} />
    } else {
      if (prestId === '3') {
        return <Navigate to="/dashboard/thanks" />
      }
      return <Navigate to="/" />
    }
  }
  return <Navigate to="/" />
}

export default PrivateBilanRoute
