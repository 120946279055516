import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  injectMatomoScript,
  logNavigationPath,
  setUserProperties,
} from '../matomo'
import { RootState } from '../redux/reducers'
import CookieNotice from './CookieNotice'

const MatomoInitializer = () => {
  const userId = useSelector((state: RootState) => state?.user?.gaInfo?.email)
  const groupId = useSelector((state: RootState) => state?.user?.gaInfo?.groupe)

  const handleCookieAccept = () => {
    injectMatomoScript()
    sessionStorage.setItem('matomo_initialized', 'true')
    if (userId && groupId) {
      setUserProperties(userId, groupId)
    }
  }

  useEffect(() => {
    const consent = Cookies.get('cookieConsent')
    if (consent === 'accepted' && userId && groupId) {
      injectMatomoScript()
      sessionStorage.setItem('matomo_initialized', 'true')
      setUserProperties(userId, groupId)
    }

    const handleBeforeUnload = () => {
      if (userId && groupId) {
        logNavigationPath(groupId)
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [userId, groupId])

  return (
    <>
      {userId && (
        <CookieNotice onAccept={handleCookieAccept} onReject={() => {}} />
      )}
    </>
  )
}

export default MatomoInitializer
